import React from 'react';
import classnames from 'classnames';

const FormPage = ({backgroundUrl, fullscreenBg, children}) => {

    const formClass = classnames('o-form-page', {
        ['o-form-page--fullscreen-bg']: fullscreenBg
    });

    return (        
        <div className={formClass}>
            <img src={backgroundUrl} className="o-form-page__background" alt="" />
            <div className="o-form-page__content">
                <div className="o-edge u-mar-vt-40">
                    <div className="o-hero-grid">
                        {children}
                    </div>
                </div>
            </div>
        </div>
    )
}

FormPage.ContentWrap = Object.assign(({children}) => (
    <div className="

        o-hero-grid__cell

        o-hero-grid__cell--row-span-7
        o-hero-grid__cell--row-span-14@60

        o-hero-grid__cell--col-span-16
        o-hero-grid__cell--col-span-8@60

    ">
        <div>  
            {children}
        </div>
    </div>
), {displayName: "ContentWrap"});

FormPage.FormWrap = Object.assign(({children}) => (
    <div className="
        o-hero-grid__cell

        o-hero-grid__cell--row-8
        o-hero-grid__cell--row-1@60
        o-hero-grid__cell--row-span-8
        o-hero-grid__cell--row-span-14@60
        
        o-hero-grid__cell--col-1
        o-hero-grid__cell--col-10@60
        o-hero-grid__cell--col-span-16
        o-hero-grid__cell--col-span-7@60
        u-color-black
    ">
        <div className="o-form-page__form">
            {children}
        </div>
    </div>
), {displayName: "FormWrap"});

FormPage.FormDisclaimers = Object.assign(({children}) => (
    <div className="
        o-hero-grid__cell

        o-hero-grid__cell--row-16
        o-hero-grid__cell--row-span-1
        
        o-hero-grid__cell--col-1
        o-hero-grid__cell--col-span-16
        o-hero-grid__cell--justify-center
        u-mar-top-20
    ">
        <div className="">
            {children}
        </div>
    </div>
), {displayName: "FormDisclosures"});

export default FormPage;