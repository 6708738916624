export const serializeFormData = (formData) => {
	const obj = {};
    formData.forEach((value, key) => {
        // Reflect.has in favor of: object.hasOwnProperty(key)
        if(!Reflect.has(obj, key)){
            obj[key] = value;
            return;
        }
        if(!Array.isArray(obj[key])){
            obj[key] = [obj[key]];    
        }
        obj[key].push(value);
    });
	return obj;
};


export const submitSalesforceForm = (formId, formData) => {
    
    const endpoint = `/api/form/${formId}/submit`;

    return fetch(endpoint, { method: "POST", body: JSON.stringify(formData) }).then(
        response => response.json(),
        error => error
    );
    
}