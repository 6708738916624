import gql from 'graphql-tag';
import Head from 'next/head';
import React, {useState} from 'react';
import {apolloClient} from '../apollo/client.js';
import {Headline1,Headline4,Textbox, Button, RadioGroupButton, RadioGroup} from '../public/bundle.js';
import FormPage from '../components/FormPage.js';
import {serializeFormData, submitSalesforceForm} from '../utilities/forms.js';
import styled from '@emotion/styled';

const SubmitContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row-reverse;
`;

const logicProperties = [
  'additionalSupport',
  'affordabilityOptions',
  'otherChallenges',
  'thirdPartySelected'
];

const backgroundUrl = "https://images.ctfassets.net/70w6ftfzv4je/6iOEUMvBUM0ql1hBdzhqRz/1b463896930c063fb920eba8ff70cfc9/artwork_18.svg";
const page_ID = "3o2XpQmEWV414HUax6zPmF";
const form_ID = "3u9fcd0id22wIGeu1G1TgP";

const RadioBtn = ({label, id, options, required, name}) => {
  return (
    <RadioGroup aria-labelledby={`${id}_group-label`}>
      <div id={`${id}_group-label`} className="b-body u-fw-light u-color-grey-4a u-mar-bottom-20">
        {label}{" "}
      </div>
      <div className="o-form-page__radio-group">
        {options.map((opt, i) => (
          <div className="b-body" key={i}>
            <RadioGroupButton label={opt} value={opt} name={name} required={required} id={`${id}_${i}`}/>
          </div>
        ))}
      </div>
    </RadioGroup>
  )
}


const FormField = ({label, salesforceField, sys, required, fieldType, radioId, ...props}) => {
  
  const typeResolver = {};
  typeResolver['RadioList'] = () => <RadioBtn label={label} name={salesforceField} id={sys?.id} required={required} {...props}/>;
  typeResolver['EmailInput'] = () => <Textbox type="email" label={label} name={salesforceField} id={sys?.id} required={required} />
  
  const Field = typeResolver[fieldType];

  return (
    <div className="u-mar-top-40">
      <div className="u-mar-vt-20">
        <Field />
      </div>
    </div>
  )
}


export default function LandingPageDisplay({pageData, formData, pageUrl}) {

  const pageTitle = pageData?.title;
  const metaImage = pageData?.socialImage?.url;
  const metaDescription = pageData?.socialDescription;

  const [hasError, setHasError] = useState(false);
  
  const {productionCampaignId, uatCampaignId, submitterType, ownerInfo, formId, formFieldsCollection, submitButtonText} = formData;

  const campaign = process.env.NODE_ENV === 'production' ? productionCampaignId : uatCampaignId;
  
  const fieldList = formFieldsCollection?.items.map(field => field.required ? Object.assign({}, field, {label: `${field.label}*`}) : field);

  const handleSubmit = (e) => {
      
      e.preventDefault();
      const formUrl = window.location.href;
      const formData = {
        campaign,
        formUrl,
        submitterType,
        ownerInfo,
        ...serializeFormData(new FormData(e.target))
      }

      const logicObj = logicProperties.reduce((arr, prop) => [...arr, formData[prop]], []);
      const slug = btoa(JSON.stringify(logicObj));

      formData.TargetUrl = slug;

      submitSalesforceForm(formId, formData).then(data => {
        
        const {isSuccess} = data;

        if (isSuccess) {
          if (formData.email) localStorage.setItem("email", formData.email);
          document.location.pathname = slug;
        } else {
          setHasError(true);
        }

      }).catch((err) => {
        console.info(err);
        setHasError(true)
      });

  }

  return (
    <FormPage backgroundUrl={backgroundUrl} fullscreenBg>
      <Head>
        <title>{pageTitle} | CoverMyMeds</title>
        <meta name="description" key="description" content={metaDescription} />

        <meta property="og:url" key="og:url" content={pageUrl} />

        <meta property="og:title" key="og:title" content={pageTitle} />
        <meta property="og:description" key="og:description" content={metaDescription} />
        <meta property="og:image" key="og:image" content={metaImage} />

        <meta name="twitter:title" key="twitter:title" content={pageTitle} />
        <meta name="twitter:description" key="twitter:description" content={metaDescription} />
        <meta name="twitter:image" key="twitter:image" content={metaImage} />
      </Head>
      <FormPage.ContentWrap>
        <Headline1>{pageTitle}</Headline1>
        <Headline4 className="u-mar-top-75 u-pad-bottom-10" Elm="h2">
          Answer a 1-minute questionnaire to get your results.
        </Headline4>
        <div className="o-article-content o-article-content--large u-mar-right-160@60">
          <p className="b-body u-mar-vt-25">
            Your path to therapy commercialization and post-launch success awaits. 
            By filling out the questionnaire, you will build the ideal plan to support 
            your brand at launch and beyond. One that transforms the patient journey 
            from a convoluted maze riddled with paperwork, red tape and system inefficiencies 
            into a clear, communicative and efficient path to better outcomes.
          </p>
          <p className="b-body u-mar-vt-25">
            We’ll begin this journey by looking at the range of support your therapy 
            and patients may need. Tell us about your brand to receive a customized preview.
          </p>
        </div>
      </FormPage.ContentWrap>
      
      <FormPage.FormWrap>
        <Headline4 Elm="h2">Tell us about your therapy to view your results.</Headline4>
        <div className={`${hasError ? 'u-disp-none' : ''}`}>
          <form onSubmit={handleSubmit}>
            {fieldList.map(item => <FormField {...item} key={item.sys.id} />)}

            <SubmitContainer className="u-mar-top-40">
              <Button className="u-mar-left-auto u-flex-shrink-0 u-mar-vt-10">{submitButtonText}</Button>
              <div className="b-caption u-color-grey-61 u-align-self-center u-mar-right-auto  u-mar-vt-10">* Required</div>
            </SubmitContainer>
          </form>
        </div>
        <div className={`b-body ${hasError ? '' : 'u-disp-none'} u-mar-top-35`}>
          There was an error submitting the form. Please try refreshing the page or check back later.
        </div>
        <div className="b-legal u-color-grey-61 u-mar-top-35">
          By entering the information above, you are made aware of the fact that CoverMyMeds may{" "}
          use your information to reach out to you. Should you have any questions or concerns{" "}
          please reach out to us at{" "}
          <a className="u-color-magenta" href="tel:866-452-5017">866-452-5017</a>.{" "}
          For additional information on how we may use{" "}
          your information provided, please see our {" "}
          <a className="u-color-magenta" href="https://www.covermymeds.health/about/privacy">Privacy Notice</a>.
        </div>
      </FormPage.FormWrap>
      <FormPage.FormDisclaimers>
        <div className="itcss">
          <div className="u-disp-flex b-body b-body--large u-fw-bold">
            <a className="u-mar-right-40 b-bare" href="https://www.covermymeds.health/about/privacy">Privacy Notice</a>
            <a className="b-bare" href="https://www.covermymeds.health/about/privacy/tos">Terms of Service</a>
          </div>
        </div>
      </FormPage.FormDisclaimers>
    </FormPage>
  )
}

export const getStaticProps = async () => {

  const { data } = await apolloClient.query({
    query: gql`
      query GetHomePageData {
        pageData: pages(id: "${page_ID}" preview: ${process.env.CONTENTFUL_PREVIEW}) {
          sys { id }
          title
          socialDescription
          socialImage {
              url
          }
        }
        formData: salesforceForms(id: "${form_ID}"  preview: ${process.env.CONTENTFUL_PREVIEW}) {
            sys { id }
            formId
            submitButtonText
            productionCampaignId
            uatCampaignId
            submitterType
            ownerInfo
            formFieldsCollection {
                items {
                    sys { id }
                    label
                    fieldType
                    salesforceField
                    required
                    defaultValue
                    options
                }
            }
        }
      }
    `
  });

  const pageData = data?.pageData || {};
  const formData = data?.formData || {};
  const pageUrl = process.env.ABSOLUTE_URL;

  return {
    props: {pageData, formData, pageUrl}, revalidate: 60
  };
}
